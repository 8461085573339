import axios from "axios";

export default {


  async getConstructorStyleSheetList() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/web_builder/business/style/base/`,
    });
  },

  async sendEmailTemplate(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/business/mail/test/send/`,
      data,
    });
  },

  async getProductLabelList() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/label/`,
    });
  },

  async createProductLabel(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/products/label/`,
      data,
    });
  },

  async updateProductLabel(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/label/`,
      data,
    });
  },
  async deleteProductLabel(classification_id) {
    return await axios({
      params: {
        id: classification_id,
      },
      method: "delete",
      url: `${process.env.VUE_APP_API}/products/label/`,
    });
  },

  async getProductsWithStock(local_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/products/product_stock/${local_id}/`,
    })
  },

  async updateProductStock(local_id, data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/products/product_stock/${local_id}/`,
      data
    })
  },

  async getBusinessWebsiteConstructorData() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/web_builder/business/styles/`,
    });
  },

  async updateBusinessWebsiteParameters(data) {
    return await axios({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "PUT",
      url: `${process.env.VUE_APP_API}/web_builder/business/styles/`,
      data: data,
    });
  },

  async deleteConstructorFooterBgImg() {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/web_builder/business/styles/`,
    });
  },

  async getBusinessWebsitePages() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/web_builder/business/web_pages/`,
    });
  },

  async createWebsitePage(data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/web_builder/business/web_pages/`,
      data: data,
    });
  },

  async updateWebsitePage(data) {
    return await axios({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "PUT",
      url: `${process.env.VUE_APP_API}/web_builder/business/web_pages/`,
      data: data,
    });
  },

  async deleteWebsitePage(web_page_id) {
    return await axios({
      method: "delete",
      params: {
        web_page_id: web_page_id
      },
      url: `${process.env.VUE_APP_API}/web_builder/business/web_pages/`,
    });
  },

  async getSectionsInWebsite(web_page_id) {
    return await axios({
      method: "get",
      params: {
        web_page_id: web_page_id
      },
      url: `${process.env.VUE_APP_API}/web_builder/business/sections/`,
    });
  },

  async createSectionInWebsite(data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/web_builder/business/sections/`,
      data: data,
    });
  },

  async updateSectionInWebsite(data) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/web_builder/business/sections/`,
      data: data,
    });
  },

  async deleteSectionInWebsite(section_id) {
    return await axios({
      method: "delete",
      params: {
        section_id: section_id
      },
      url: `${process.env.VUE_APP_API}/web_builder/business/sections/`,
    });
  },

  async getSectionsImageInWebsite() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/web_builder/business/sections/image/`,
    });
  },

  async createSectionImageInWebsite(data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/web_builder/business/sections/image/`,
      data: data,
    });
  },

  async updateSectionImageInWebsite(data) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/web_builder/business/sections/image/`,
      data: data,
    });
  },

  async deleteSectionImageInWebsite(section_image_id) {
    return await axios({
      method: "delete",
      params: {
        section_image_id: section_image_id
      },
      url: `${process.env.VUE_APP_API}/web_builder/business/sections/image/`,
    });
  },

  async getDecorationFloorList(local_id){
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/order/table/floor/${local_id}/decoration/`
    })
  },

  async getLocalFloorList(local_id) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/order/table/floor/${local_id}/`,
    });
  },

  async createSingleLocalFloor(local_id) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/order/table/floor/${local_id}/`
    });
  },

  async updateSingleLocalFloor(local_id, data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/order/table/floor/${local_id}/`,
      data,
    });
  },

  async deleteSingleLocalFloor(local_id, floor_id) {
    return await axios({
      params: {
        id: floor_id,
      },
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/order/table/floor/${local_id}/`,
    });
  },

  async getCostList(local_id) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/local/${local_id}/cost/`,
    });
  },

  async createCostObject(local_id, data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/local/${local_id}/cost/`,
      data,
    });
  },

  async updateCostObject(local_id, data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/local/${local_id}/cost/`,
      data,
    });
  },
  async deleteCostObject(local_id, classification_id) {
    return await axios({
      params: {
        id: classification_id,
      },
      method: "delete",
      url: `${process.env.VUE_APP_API}/local/${local_id}/cost/`,
    });
  },
    async createAdminUser (data) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/user/admin/create/`,
            data
        })
    },
  async getCurrentChatbot() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/business/bot/whatsapp/`,
    });
  },

  async createWhatsAppBot() {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/business/bot/whatsapp/`
    });
  },

  async stopWhatsAppBot() {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/business/bot/whatsapp/`,
    });
  },

  async deleteOldWhatsAppChatBotAndCreateNew() {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/business/bot/whatsapp/`,
    });
  },

  async sendWhatsAppChatBotMessage(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/business/bot/whatsapp/messaging/`,
      data,
    });
  },

  async updateChatBotDefaultMessages(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/business/bot/whatsapp/messaging/`,
      data,
    });
  },

  async getLocalReservations(local_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/booking/${local_id}/`,
    });
  },

  async getBusinessCurrentWaiters(local_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/business/local/waiters/${local_id}/`,
    });
  },

  async getLocalKitchenAreaList(local_id) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/kitchen_area/${local_id}/`,
    });
  },

  async createKitchenArea(
    local_id,
    data = {
      name: "",
    }
  ) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/products/kitchen_area/${local_id}/`,
      data,
    });
  },

  async updateKitchenArea(
    local_id,
    data = {
      id: 0,
      name: "",
    }
  ) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/products/kitchen_area/${local_id}/`,
      data,
    });
  },

  async deleteKitchenArea(
    local_id,
    data = {
      id: 0,
    }
  ) {
    return await axios({
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/products/kitchen_area/${local_id}/`,
      data,
    });
  },

  async getInventoryDiscrepancyData() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/warehouse/balance/`,
    });
  },

  async uploadInventoryBalance(warehouse, data) {
    return await axios({
      method: "POST",
      params: {
        warehouse: warehouse
      },
      url: `${process.env.VUE_APP_API}/products/warehouse/balance/`,
      data,
    });
  },

  async updateDiscrepancyInfo(data) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/products/warehouse/balance/`,
      data,
    });
  },

  async getInventoryDiscrepancyByDates(data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/products/warehouse/balance/report/`,
      data,
    });
  },

  async uploadInventoryBalanceFileSheet(data) {
    return await axios({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      url: `${process.env.VUE_APP_API}/products/warehouse/balance/file/`,
      data,
    });
  },

    async uploadIngredientExcel (data) {
        return await axios({
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'POST',
            url: `${process.env.VUE_APP_API}/products/recipe/ingredient/massive/`,
            data
        })
    },

  async getWarehouseOutcome() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/warehouse/outcome/`,
    });
  },

  async createWarehouseOutcome(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/products/warehouse/outcome/`,
      data,
    });
  },

  async updateWarehouseOutcome(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/warehouse/outcome/`,
      data,
    });
  },

  async getHistoricIncome(data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/products/warehouse/income/historic/`,
      data
    });
  },

  async getHistoricCuadre(data) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/products/warehouse/income/historic/`,
      data
    });
  },

  async getWarehouseIncome() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/warehouse/income/`,
    });
  },

  async createWarehouseIncome(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/products/warehouse/income/`,
      data,
    });
  },

  async registerWarehouseIncomeFromCashRegister(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/warehouse/income/`,
      data,
    });
  },

  async getSingleLoteInfoMovements(provision_id) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/warehouse/income/detail/${provision_id}`,
    });
  },

  async getBusinessWarehouses() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/warehouse/`,
    });
  },

  async createBusinessWarehouse(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/products/warehouse/`,
      data,
    });
  },

  async updateBusinessWarehouse(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/warehouse/`,
      data,
    });
  },

  async getBusinessIngredients() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/recipe/ingredient/`,
    });
  },

  async createIngredientInventory(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/products/recipe/ingredient/`,
      data,
    });
  },

  async updateIngredientInventory(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/recipe/ingredient/`,
      data,
    });
  },

  async deleteIngredientInventory(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/products/recipe/ingredient/`,
      data,
    });
  },

  async getIngredientByIdList(data = {
    ingredient_list: [0]
  }) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/products/recipe/ingredient/list/`,
      data
    });
  },

  async getIngredientClassificationList() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/recipe/classification/`,
    });
  },

  async createIngredientClassification(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/products/recipe/classification/`,
      data,
    });
  },

  async updateIngredientClassification(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/recipe/classification/`,
      data,
    });
  },
  async deleteIngredientClassification(classification_id) {
    return await axios({
      params: {
        id: classification_id,
      },
      method: "delete",
      url: `${process.env.VUE_APP_API}/products/recipe/classification/`,
    });
  },

  async getBusinessSubRecipes() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/recipe/sub_recipe/`,
    });
  },

  async createSubRecipe(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/products/recipe/sub_recipe/`,
      data,
    });
  },

  async updateSubRecipe(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/recipe/sub_recipe/`,
      data,
    });
  },

  async deleteSubRecipe(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/products/recipe/sub_recipe/`,
      data,
    });
  },

  async getLatestVersion(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/business/check_version/`,
      data,
    });
  },

  async getBusinessHistoricInfo(
    data = {
      date_start: "",
      date_end: "",
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/business/historic/info/`,
      data,
    });
  },

  async getLocalsHistoricInfo(
    data = {
      date_start: "",
      date_end: "",
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/business/historic/info/locals/`,
      data,
    });
  },

  async getDriversHistoricInfo(
    data = {
      date_start: "",
      date_end: "",
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/business/historic/info/drivers/`,
      data,
    });
  },

  async getSoldProductsHistoricInfo(
    data = {
      date_start: "",
      date_end: "",
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/business/historic/info/products/`,
      data,
    });
  },

  async getPlatformUpgradesHistoricInfo(
    data = {
      date_start: "",
      date_end: "",
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/business/historic/info/upgrades/`,
      data,
    });
  },

  async getPromoProductsCampaigns() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/campaign/`,
    });
  },

  async createNewPromoProductCampaign(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/products/campaign/`,
      data,
    });
  },

  async updatePromoProductCampaigns(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/campaign/`,
      data,
    });
  },

  async deletePromoProductCampaign(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/products/campaign/`,
      data,
    });
  },

  async getMotorizedOrderTimesInfo(order_id) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/order/delivery_times/${order_id}`,
    });
  },

  async createNewCategoryMenu(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/category/update/`,
      data,
    });
  },

  async updateCategoryInfo(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/category/update/`,
      data,
    });
  },

  async deleteCategoryFromMenu(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/category/update/`,
      data,
    });
  },

  async updateCategoryPosition(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/category/position/`,
      data,
    });
  },

  async uploadCategoryPhoto(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/category/photo/`,
      data,
    });
  },

  async deleteCategoryPhoto(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/category/photo/`,
      data,
    });
  },

  async createNewProduct(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/products/`,
      data,
    });
  },

  async createNewSize(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/sizes/`,
      data,
    });
  },

  async createNewAdditionalItem(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/additional_items/admin/`,
      data,
    });
  },

  async getBusinessList() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/business/list/`,
    });
  },

  async getUserBasicList() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/business/profile/change/`,
    });
  },

  async getAccessProfiles() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/business/profile/`,
    });
  },

  async createAccessProfile(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/business/profile/`,
      data,
    });
  },

  async changeAccessProfile(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/business/profile/`,
      data,
    });
  },

  async deleteAccessProfile(profile_id) {
    return await axios({
      method: "delete",
      params: {
        'profile_id': profile_id
      },
      url: `${process.env.VUE_APP_API}/business/profile/`
    });
  },

  async updateProductImage(data) {
    return await axios({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "put",
      url: `${process.env.VUE_APP_API}/product/photo/`,
      data,
    });
  },

  async updateAdditionalImage(data) {
    return await axios({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "put",
      url: `${process.env.VUE_APP_API}/additional_item/photo/`,
      data,
    });
  },

  async updateSizeItemImage(data) {
    return await axios({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "put",
      url: `${process.env.VUE_APP_API}/size/photo/`,
      data,
    });
  },

  async updateProductSizeImage(data) {
    return await axios({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "put",
      url: `${process.env.VUE_APP_API}/product_sizes/photo/`,
      data,
    });
  },

  async deleteProductImage(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/product/photo/`,
      data,
    });
  },

  async deleteAdditionalImage(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/additional_item/photo/`,
      data,
    });
  },

  async deleteSizeItemImage(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/size/photo/`,
      data,
    });
  },

  async deleteProductSizeImage(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/product_sizes/photo/`,
      data,
    });
  },

  async getAllComplaints() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/complaints_book/`,
    });
  },

  async updateComplaint(
    data = {
      id: 0,
      is_solved: false,
    }
  ) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/complaints_book/`,
      data,
    });
  },

  async getAllJobSeekers() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/jobs/apply/`,
    });
  },

  async generateTrackingLink(
    data = {
      order_id: 0,
    }
  ) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/tracking_link/`,
      data,
    });
  },
  async getHistoricUsers(
    data = {
      date_start: "",
      date_end: "",
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/historic/users/`,
      data,
    });
  },

  async getHistoricCallCenterUsers(
      data = {
        date_start: "",
        date_end: "",
      }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/historic/users/call_center/`,
      data,
    });
  },

  async searchUserInfo(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/historic/users/search/`,
      data,
    });
  },
  async getAllUsersData() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/historic/users/`,
    });
  },

  async getCallCenterHistoricUserList() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/historic/users/call_center/`,
    });
  },

  async getAllLocalProducts(local_id) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/historic/products/local/${local_id}`,
    });
  },

  async getAllProducts() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/historic/products/`,
    });
  },

  async getAllAdditionals() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/historic/additional_items/`,
    });
  },

  async getAllSizes() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/historic/sizes/`,
    });
  },

  async getAllCategories() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/historic/categories/`,
    });
  },
  async getHistoricSunatInvoices(
    data = {
      date_start: "",
      date_end: "",
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/historic/orders/sunat/`,
      data,
    });
  },
  async getHistoricSunatQuickeatInvoices(
    data = {
      date_start: "",
      date_end: "",
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/historic/orders/sunat/quickeat/`,
      data,
    });
  },
  async searchHistoricOrder(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/historic/orders/search/`,
      data,
    });
  },
  async getHistoricOrders(
    data = {
      date_start: "",
      date_end: "",
      status: "",
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/historic/orders/`,
      data,
    });
  },
  async getPlatesByOrderIdList(
    data = {
      order_list: [],
    }
  ) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/historic/order_details/`,
      data,
    });
  },
  async getPlatesByDates(data) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/historic/order_details/`,
      data,
    });
  },
  async getSizesByDates(data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/historic/order_details/sizes/`,
      data,
    });
  },
  async getHistoricMotorizedOrders(
    data = {
      date_start: "",
      date_end: ""
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/historic/orders/motorized/`,
      data,
    });
  },
  async getMenuCategoriesWithSchedules(local_id) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/category/admin/schedule/${local_id}`,
    });
  },
  async createCategorySchedule(local_id, data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/category/admin/schedule/${local_id}`,
      data
    });
  },
  async deleteCategorySchedule(local_id, category_id) {
    return await axios({
      params: {
        'id': category_id
      },
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/category/admin/schedule/${local_id}`
    });
  },
  async getMenuListProducts(type, is_recipe, local_id) {
    return await axios({
      params: {
        type,
        is_recipe,
      },
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/menu/admin/${local_id}`,
    });
  },
  async uploadProductExcel (local_id, data) {
    return await axios({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      method: 'POST',
      url: `${process.env.VUE_APP_API}/products/menu/admin/${local_id}`,
      data
    })
  },
  async unlinkMenuItemSku(type, item_id, local_id) {
    return await axios({
      params: {
        type,
        item_id,
      },
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/products/menu/admin/${local_id}`,
    });
  },
  async get_all_products_local(type, local_id) {
    return await axios({
      params: {
        type,
      },
      method: "GET",
      url: `${process.env.VUE_APP_API}/category/admin/${local_id}`,
    });
  },

  async change_product_available(
    data = {
      id: 0,
    },
    type,
    local_id
  ) {
    return await axios({
      params: {
        type,
      },
      method: "put",
      url: `${process.env.VUE_APP_API}/category/admin/${local_id}`,
      data,
    });
  },

  async change_size_item_info(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/sizes/`,
      data,
    });
  },

  async deleteSizeItem(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/sizes/`,
      data,
    });
  },

  async change_additional_info(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/additional_items/admin/`,
      data,
    });
  },

  async deleteAdditionalItem(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/additional_items/admin/`,
      data,
    });
  },

  async updateExistingProductItemLabel(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/update/product/label/`,
      data
    })
  },

  async updateExistingSizeItemLabel(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/update/size/label/`,
      data
    })
  },

  async change_product_info(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/`,
      data,
    });
  },

  async deleteProductMenu(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/products/`,
      data,
    });
  },

  async add_product_sizes(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/product_sizes/update/`,
      data,
    });
  },

  async delete_product_size(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/product_sizes/update/`,
      data,
    });
  },

  async getListMotorized() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/motorized/`,
    });
  },

  async changeMotorizedInfo(
    data = {
      local: 1,
    },
    id
  ) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/motorized/${id}/`,
      data,
    });
  },

  async deleteMotorized(id) {
    return await axios({
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/motorized/${id}/`,
    });
  },

  async getHistoricCashTurnsInfo(
    data = {
      date_start: "",
      date_end: "",
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/historic/cash_register/`,
      data,
    });
  },

  async getHistoricTableTurnsInfo(
      data = {
        date_start: "",
        date_end: "",
      }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/historic/table_turns/`,
      data,
    });
  },

  async getHistoricIngredientConsumptionList(
      data = {
        date_start: "",
        date_end: "",
      }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/products/recipe/ingredient/consumption/`,
      data,
    });
  },
  async getHistoricProductLabelConsumptionList(
      data = {
        date_start: "",
        date_end: "",
      }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/products/recipe/label/consumption/`,
      data,
    });
  },

  async add_base_recipe_data(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/products/recipe/base_recipe/`,
      data,
    });
  },

  async delete_single_recipe_item(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/products/recipe/base_recipe/`,
      data,
    });
  },

  async change_size_item_recipe_data(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/recipe/size_item/`,
      data,
    });
  },

  async delete_size_item_recipe_data(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/products/recipe/size_item/`,
      data,
    });
  },

  async update_product_recipe_data(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/recipe/product/`,
      data,
    });
  },

  async delete_product_recipe_data(prod_id) {
    return await axios({
      params: {
        "product_id": prod_id
      },
      method: "delete",
      url: `${process.env.VUE_APP_API}/products/recipe/product/`,
    });
  },

  async update_additional_item_recipe_data(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/products/recipe/additional_item/`,
      data,
    });
  },

  async delete_additional_item_recipe_data(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/products/recipe/additional_item/`,
      data,
    });
  },
};
