<template>
  <div id="app__wrapper">
    <RebrandHeader :style="documentTypeForPrint > 0 ? 'display:none' : ''">
    </RebrandHeader>
    <transition name="slide-fade">
      <ModalLogin
          v-if="!isAuthenticated && showModalLoginRegister"
          :active="showModalLoginRegister"
      ></ModalLogin>
    </transition>
    <main class="main" v-show="documentTypeForPrint === 0">
      <router-view> </router-view>
    </main>
    <ModalPrintOrderInfo
        v-if="documentTypeForPrint === 1"
        @finish="finished_printing"
    >
    </ModalPrintOrderInfo>
    <ModalPrintInvoice
        v-if="documentTypeForPrint === 2"
        @finish="finished_printing"
    >
    </ModalPrintInvoice>
    <ModalPrintKitchenInfo
        v-if="documentTypeForPrint === 3"
        @finish="finished_printing"
    >
    </ModalPrintKitchenInfo>
    <transition name="slide-fade">
      <SingleButtonGenericModal
          v-if="showModalOptionalNotification"
          :title="''"
          :content="'Por favor, habilita las notificaciones en tu navegador web'"
          :accept="'Regresar'"
          @accepted_modal="showModalOptionalNotification = false"
          @close_modal="showModalOptionalNotification = false"
      >
      </SingleButtonGenericModal>
    </transition>

    <transition name="slide-fade">
      <SingleButtonGenericModal
          v-if="showModalNotificationsAllowed"
          :title="''"
          :content="'Notificaciones activadas con éxito'"
          :accept="'Regresar'"
          @accepted_modal="showModalNotificationsAllowed = false"
          @close_modal="showModalNotificationsAllowed = false"
      >
      </SingleButtonGenericModal>
    </transition>

    <transition name="slide-fade">
      <ModalWaiterNotificationRequest
          v-if="showModalConfirmNotification"
          :title="'Acción necesaria'"
          :content="'Para continuar, debe aceptar recibir notificaciones a su dispositivo'"
          :accept="'Aceptar'"
          :cancel="'Salir'"
          @close_modal="showModalConfirmNotification = false"
          @accepted_modal="requestPermissionWaiter"

      ></ModalWaiterNotificationRequest>
    </transition>

    <transition name="slide-fade">
      <SingleButtonGenericModal
          v-if="showPushUpModal"
          :title="notification.title"
          :content="notification.content"
          :accept="'Aceptar'"
          @accepted_modal="showPushUpModal = false"
          @close_modal="showPushUpModal = false"
      >
      </SingleButtonGenericModal>
    </transition>

  </div>
</template>

<script setup>
import UserServices from "@/services/user-services";
import { db } from "@/firebase";
import { onSnapshot, collection } from "firebase/firestore";
import * as Sentry from "@sentry/vue";
import store from "@/store/store";
import ModalPrintOrderInfo from "@/components/MODALS/ModalPrintOrderInfo";
import ModalPrintInvoice from "@/components/MODALS/ModalPrintInvoice";
import moment from "moment";
import {firebaseConfig, messaging, firebase, firebaseApp} from "@/firebase";
import RebrandHeader from "@/components/MainHeader";
import ModalLogin from "@/components/MODALS/ModalLogin";
import {getToken, onMessage} from "firebase/messaging";
import ModalPrintKitchenInfo from "@/components/MODALS/ModalPrintKitchenInfo";
import ModalWaiterNotificationRequest from "@/components/MODALS/ModalWaiterNotificationRequest.vue";
import SingleButtonGenericModal from "@/components/Recursos/SingleButtonGenericModal.vue";
import {computed, getCurrentInstance, onMounted, onBeforeUnmount, ref, watch} from "vue";
import {useRouter} from "vue-router";

const { appContext } = getCurrentInstance();
const toast = appContext.config.globalProperties.$toast;
const notificationFunc = appContext.config.globalProperties.$notification
const router = useRouter();


const businessIdentifier = computed(() => store.state.businessIdentifier);
const businessLocalId = computed(() => store.state.businessLocalId);
const showModalLoginRegister = computed(() => store.state.showModalLoginRegister);
const isAuthenticated = computed(() => store.state.isAuthenticated);
const configurationsAdminWeb = computed(() => store.state.configurationsAdminWeb);
const user = computed(() => store.state.user);
const webVersion = computed(() => store.state.webVersion);
const documentTypeForPrint = computed(() => store.state.documentTypeForPrint);
const platform = computed(() => store.state.platform);
const reg_id = computed(() => store.state.reg_id);

const setAuthenticatedFalse = () => store.commit("setAuthenticatedFalse");
const setWebVersion = (data) => store.commit('setWebVersion', data);
const setUser = (data) => store.commit('setUser', data);


const showPushUpModal = ref(false);
const notification = ref({
  title: '',
  content: ''
});
const initialListen = ref(true);
const showModalNotificationsAllowed = ref(false);
const showModalOptionalNotification = ref(false);
const showModalConfirmNotification = ref(false);
const current_state_notif = ref(false);
const subscription_firebase = ref(null);
const fechaLimite = ref("");
const fechaActual = ref(moment());
const mensaje_moroso = ref("Servicio suspendido hasta que regularicen su pago.");
const myTrack = ref(null);
const hasLoadedWorker = ref(false);

watch(isAuthenticated, async () => {
  initialListen.value = true;
  if (subscription_firebase.value !== null) {
    console.log("desactivara firestore");
    subscription_firebase.value();
    subscription_firebase.value = null;
  }
  if (isAuthenticated.value) {
    if (user.value.force_logout) {
      await logoutAndClearData();
      window.alert(mensaje_moroso.value);
      return;
    }
    if (user.value.waiter) {
      // showModalConfirmNotification.value = true // TODO: aqui se activan notificaciones push de mesero


      // navigator.serviceWorker.addEventListener('message', (event) => {
      //   if (event.data.type === 'showModal') {
      //     // Muestra el modal con los datos recibidos
      //     notification.value.title = event.data.title;
      //     notification.value.content = event.data.content;
      //     showPushUpModal.value = true
      //   }
      // });
      return
    }
    if (user.value.is_staff || user.value.super_admin || user.value.admin) {
      console.log("llamara firestore");
      setTimeout(async () => {
        await activeOrdersNotificationPermissions();
        await firestoreListennerApp();
      }, 500);
      return;
    }
    if (user.value.business_staff && user.value.access_list.includes("1")) {
      console.log("firestore business_staff");
      setTimeout(async () => {
        await activeOrdersNotificationPermissions();
        await firestoreListennerApp();
      }, 500);
    }
  } else {
    unregisterServiceWorker()
  }

});


async function logoutAndClearData() {
  try {
    if (user.value.waiter === true) {
      let response = await UserServices.logoutUserRegId({
        platform: platform.value,
        reg_id: reg_id.value
      })
      if (response.data.success) {
        console.log(response.data.data)
      } else {
        console.log(response.data.message)
      }
    }
  } catch (e) {
    console.log("error en fcm logout", e)
  }
  unregisterServiceWorker()
  showModalConfirmNotification.value = false
  setAuthenticatedFalse()
  localStorage.clear();
  await UserServices.setBusinessHeader("");
  await UserServices.setAuthHeaders(null);
  await router.push({path: `/`, query: {headerTitle: 'Inicio'}});
}

onMounted(() => {

  let metaThemeColor = document.querySelector("meta[name=theme-color]");
  metaThemeColor.setAttribute(
      "content",
      getComputedStyle(document.body).getPropertyValue("--primary")
  ); //setea el theme color desde una variable css
  console.log("connection is", process.env.NODE_ENV);


  window.addEventListener('beforeunload', handleBeforeUnload);
  fechaLimite.value = moment({
    year: fechaActual.value.year(),
    month: fechaActual.value.month(),
    date: 16,
    hour: 23,
    minute: 59,
    second: 0,
  });

  setTimeout(() => {
    if (process.env.NODE_ENV !== "development") {
      console.log("no es dev");
      if (isAuthenticated.value) {
        Sentry.setUser({
          email: user.value.email,
          phone: user.value.phone,
          full_name: user.value.first_name + " " + user.value.last_name,
          business: businessIdentifier.value,
        });
      } else {
        Sentry.configureScope((scope) => scope.setUser(null));
      }
    }
  }, 500);

  setTimeout(async () => {
    console.log("businessIdentifier else", businessIdentifier.value)
    if (businessIdentifier.value !== '') {
      if (isAuthenticated.value) {
        await UserServices.setAuthHeaders(user.value.token);
        await UserServices.setBusinessHeader(businessIdentifier.value);
        await getUserPermissions();
      }
    } else {
      console.log("llama else")
      await logoutAndClearData();
      return
    }
    if (webVersion.value < parseInt(process.env.VUE_APP_CURRENT_VERSION) && webVersion.value !== 0) {
      toast.info("Se refrescará su navegador web para que tenga la versión más reciente.");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    setWebVersion(parseInt(process.env.VUE_APP_CURRENT_VERSION));
  }, 100);
})

onBeforeUnmount(() => {
  // Remover el listener cuando se destruya el componente
  window.removeEventListener('beforeunload', handleBeforeUnload);
})

function finished_printing() {
  store.commit('setDocumentTypeForPrint', 0);
}
async function getUserPermissions() {
  try {
    let response = await UserServices.infoUser();
    if (response.data.success) {
      if (response.data.data.force_logout) {
        await logoutAndClearData();
        window.alert(mensaje_moroso.value);
        return;
      }
      setUser(response.data.data);
      store.commit('setBusinessLocalId', response.data.data.local);
      return
    }
    toast.error(response.data.message);
    await logoutAndClearData();
  } catch (e) {
    console.log("error es", e);
    if (e.response.status === 500 || e.response.status === 401) {
      await logoutAndClearData();
      toast.error("Hubo un error. Tiene que volver a iniciar sesión para continuar.");
    } else {
      toast.error(e.toString());
    }
  }
}
async function activeOrdersNotificationPermissions() {
  console.log("solicitara permiso")
  if (!configurationsAdminWeb.value.reservation && !configurationsAdminWeb.value.takeout && !configurationsAdminWeb.value.delivery) {
    return;
  }
  if ('Notification' in window) {
    Notification.requestPermission().then((result) => {
      console.log("result inicial", result)
      if (result === "granted") {
        console.log("Ya tiene permitido por defecto");
        showModalNotificationsAllowed.value = true
        //aceptado
      } else if (result === "prompt") {
        console.log("debe preguntar");
      } else if (result === "denied") {
        //denegado
        console.log("tiene denegado");
        showModalOptionalNotification.value = true;
      }
    });
  } else {
    notification.value.title = 'Error'
    notification.value.content = 'Las notificaciones no están soportadas en este navegador.'
    showPushUpModal.value = true
  }
}
async function firestoreListennerApp() {
  if (businessIdentifier.value === '') {
    console.log("no puede hacer listen fs")
    return;
  }
  myTrack.value = new Audio(require("@/audio/audio.mp3"));

  subscription_firebase.value = onSnapshot(
      collection(
          db, `quickeat/${businessIdentifier.value}/${businessLocalId.value}`
      ),
      {includeMetadataChanges: true},
      (snapshot) => {
        if (initialListen.value) {
          console.log("hizo initial listen app.vue");
          initialListen.value = false;
        } else {
          snapshot.docChanges().forEach((snapshot) => {
            if (snapshot.type === "added") {
              if (process.env.NODE_ENV === "development") {
                console.log("se añadió info a app.vue", snapshot.doc.data());
              }

              if (snapshot.doc.data().status !== "0") {
                return;
              }

              switch (snapshot.doc.data().type_delivery) {
                case "1":
                case 1:
                  if (!configurationsAdminWeb.value.reservation) {
                    return;
                  }
                  break;
                case "2":
                case 2:
                  if (!configurationsAdminWeb.value.takeout) {
                    return;
                  }
                  break;
                case "3":
                case 3:
                  if (!configurationsAdminWeb.value.delivery) {
                    return;
                  }
                  break;
                case "4":
                case 4:
                  return;
              }

              document.addEventListener("click", handleClickOutside);

              myTrack.value.loop = true;
              try {
                myTrack.value.play();
              } catch (e) {
                toast.error('No se pudo reproducir sonido de nueva orden')
                console.log(e)
              }

              let response = snapshot.doc.data().message;

              const notification = {
                title: "Quick eat!",
                options: {
                  body: response,
                  requireInteraction: true,
                  icon: "https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png",
                  image:
                      "https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png",
                  badge:
                      "https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png",
                },
                events: {
                  onerror: function () {
                    //console.log('Error en la notif');
                  },
                  onclick: async function () {
                    myTrack.value.pause();
                    myTrack.value.currentTime = 0;
                    document.removeEventListener(
                        "click",
                        handleClickOutside
                    );
                    if (`${businessLocalId.value}` !== "0") {
                      await router.push("/orders");
                    } else {
                      let id_local = snapshot.doc.data().local_id;
                      let local_obj = {
                        id: id_local,
                        name: snapshot.doc.data().local_name,
                      };
                      store.commit("setLocalSuperAdmin", local_obj);
                      await router.push({
                        path: `/ordersSuperAdmin/${id_local}`,
                        query: {headerTitle: `Órdenes - ${local_obj.name}`}
                      })
                    }
                  },
                  onclose: function () {
                    myTrack.value.pause();
                    myTrack.value.currentTime = 0;
                    document.removeEventListener(
                        "click",
                        handleClickOutside
                    );
                  },
                  onshow: function () {
                  },
                },
              };
              notificationFunc.show(
                  notification.title,
                  notification.options,
                  notification.events
              );
              toast.success(response);
            }
          });
        }
      }
  );
}

async function requestPermissionAndGetToken(registration) {

  try {
    const token = await getToken(messaging, {
      vapidKey: 'BLqObJ9ZFsmP3nzWJcBSWyIbCNf-JZQ2czubvzS7cNDCmyvRpxVmXY1Jqdn8Chmy0_S6AwrP4cSegQekpFUrV2g',
      serviceWorkerRegistration: registration
    });
    if (token) {
      store.commit('setReg_id', token)
      let response = await UserServices.updateRegId({
        reg_id: token,
        platform: platform.value,
        role: 3
      })
      if (response.data.success) {
        console.log("token front registrado con exito")
      } else {
        console.log("token front error")
      }
      onMessage(messaging, (payload) => {
        console.log("notif front", payload)
        const title = payload.data.title
        const body = payload.data.message
        const options = {
          body: body,
          icon: "https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png",
        };
        registration.showNotification(payload.data.title, options);
        notification.value.title = title
        notification.value.content = body
        showPushUpModal.value = true
      })
    }
  } catch (error) {
    console.error('Error al solicitar permisos de notificación o al obtener el token de FCM:', error);
  }
}

function registerFirebaseMessagingSW() {
  if (hasLoadedWorker.value) {
    toast.error("Ya se registró el service worker")
    return
  }
  if (messaging == null) {
    toast.error("Este navegador web no es compatible con notificaciones push.")
    return
  }
  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data.type === 'showModal') {
      showModalWithMessage(event.data.title, event.data.body);
    }
  });
  const firebase_full_config = firebaseConfig
  firebase_full_config.user_token = user.value.token
  firebase_full_config.base_api = `${process.env.VUE_APP_API}/user/reg_id/`
  firebase_full_config.platform = platform.value
  if ('serviceWorker' in navigator) {

    navigator.serviceWorker.register('/firebase-messaging-sw_new.js?config=' + encodeURIComponent(JSON.stringify(firebase_full_config)))
        .then((registration) => {
          console.log('Service Worker registrado con éxito:', registration);
          hasLoadedWorker.value = true
          setTimeout(() => {
            requestPermissionAndGetToken(registration);
          }, 5000)
        })
        .catch((error) => {
          console.error('Error al registrar el Service Worker:', error);
        });
  }
}

function showModalWithMessage(title, body) {
  notification.value.title = title
  notification.value.content = body
  showPushUpModal.value = true
}

function requestPermissionWaiter() {
  if (!isAuthenticated.value) {
    return
  }
  if ('Notification' in window) {
    Notification.requestPermission().then((permission) => {
      current_state_notif.value = permission
      if (permission === "granted") {
        showModalConfirmNotification.value = false
        registerFirebaseMessagingSW()
        current_state_notif.value = permission
      } else {
        showModalConfirmNotification.value = false
      }
    })
    setTimeout(() => {
      if (current_state_notif.value !== 'granted') {
        toast.error("Debe actualizar la configuración en su navegador web.")
      }
    }, 300)
  } else {
    notification.value.title = 'Error'
    notification.value.content = 'Las notificaciones no están soportadas en este navegador.'
    showPushUpModal.value = true
  }
}

function handleClickOutside() {
  // Detener la reproducción del sonido aquí
  if (myTrack.value.currentTime === 0) {
    return;
  }
  myTrack.value.pause();
  myTrack.value.currentTime = 0;

  // También puedes eliminar el event listener para evitar múltiples llamadas innecesarias
  document.removeEventListener("click", handleClickOutside);
}

function unregisterServiceWorker() {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      console.log("reg es", registration)
      if (registration.active && registration.active.scriptURL.includes('/firebase-messaging-sw_new.js')) {
        registration.unregister().then((success) => {
          if (success) {
            hasLoadedWorker.value = false
            console.log('Service Worker desregistrado con éxito:', registration);
          } else {
            console.error('Error al desregistrar el Service Worker.');
          }
        });
      }
    });
  }).catch((error) => {
    console.error('Error al obtener los Service Workers:', error);
  });
}

async function handleBeforeUnload() {
  // Verificar que la app de Firebase existe antes de intentar eliminarla
  if (firebaseApp) {
    await firebase.deleteApp(firebaseApp)
  }
}
</script>
<style scoped>
#app__wrapper {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  background: #f7f7f7;
}

.main {
  height: 100%;
  overflow: auto;
}
</style>
