import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import "@/assets/css/mystyle.scss";
import "@/assets/css/main.css";
import "@/components/ConstructorWebComponents/Buttons/ButtonsConstructor.css"
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import * as uiv from "uiv";
import Vue3Lottie from "vue3-lottie";
import "vue3-lottie/dist/style.css";
import "@/assets/css/ui_kit_quickeat.css";
import "@/assets/styles/style.css";
import "@/assets/css/qe_datepicker.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";

// import VueGtag from "vue-gtag"

const app = createApp(App);
app.use(uiv);
app.use(Vue3Lottie);
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "visualization", // falto 'places' pero en array creo q hay error
  },
});

// Router and Store
app.use(router);
app.use(store);

import * as Sentry from "@sentry/vue";
if (process.env.NODE_ENV !== "development") {
  console.log("importa sentry mainjs");
  Sentry.init({
    app,
    dsn: "https://220476ddeb684966b21ebb2e894ab92b@o1061511.ingest.sentry.io/6198282",
    beforeSend(event) {
      // Verifica si el mensaje de error contiene el texto que quieres filtrar
      if (event.message && event.message.includes('Notification prompting can only be done from a user gesture.')) {
        return null; // Ignora el error
      }
      return event; // Procesa el resto de los eventos normalmente
    }
  });
}

app.use(ToastPlugin, {
  position: "top-right",
  duration: 5000,
});
// app.use(VueGtag, {
//     config: { id: "G-8V4HJ4S7MR" }
// })

import Vue3NativeNotification from "vue3-native-notification";
app.use(Vue3NativeNotification, {
  requestOnNotify: true,
});

// Mount app
app.mount("#app");
