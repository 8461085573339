<template>
  <div class="modal__bg" @click="closeModalLogin()">
    <form
        class="modal__container-base"
        @click.stop
        @submit.prevent="loginButton"
    >
      <svg class="icon-close-modal"
           @click="closeModalLogin"
           width="24"
           height="24"
           viewBox="0 0 24 24"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
            fill="currentColor"
        />
      </svg>
      <div class="component_login-information">
        <h2 class="title-modal">¡Te damos la bienvenida!</h2>
        <p>Inice sesión para continuar</p>
      </div>
      <SelectComponent
          v-if="is_socios_website"
          :optionsSelect="business_list"
          :name="'select_business_name'"
          :valueObj="'name'"
          :required="true"
          :placeholder="'Nombre del negocio'"
          :bg_color="'var(--text-white)'"
          v-model="businessName"
          :key="reset_key"
      >
      </SelectComponent>
      <InputComponent
          :placeholder="'Correo'"
          :type="'email'"
          :required="true"
          :bg_color="'#F7F7F7'"
          v-model="login.email"
      >
      </InputComponent>
      <PasswordComponent
          :placeholder="'Contraseña'"
          :required="true"
          :bg_color="'#F7F7F7'"
          :disabled="loading"
          v-model="login.password"
      >
      </PasswordComponent>
      <ButtonPrimary type="submit" :disabled="loading" :loading="loading">
        {{ login_words }}
      </ButtonPrimary>
    </form>
  </div>
</template>

<script setup>
import AdminServices from "@/services/admin-services";
import User from "@/services/user-services";
import InputComponent from "@/components/new/inputs/InputComponent";
import PasswordComponent from "@/components/new/inputs/PasswordComponent";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
import SelectComponent from "@/components/new/inputs/SelectComponent";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {computed, defineProps, defineEmits, getCurrentInstance, onMounted, ref, watch, toRef} from "vue";

const props = defineProps(['active']);
const emits = defineEmits(['close']);

const { appContext } = getCurrentInstance();
const toast = appContext.config.globalProperties.$toast;

const activeRef = toRef(props, 'active');
const store = useStore();
const router = useRouter();
const loading = ref(false);
const businessName = ref(null);
const login = ref({
  email: "",
  password: "",
  platform: "Web",
});
const business_list = ref([]);
const notificationStatus = ref({
  takeout: true,
  reservation: true,
  delivery: true,
  auto_print_orders: false,
  auto_print_invoices: false,
  auto_print_software: false
});
const reset_key = ref(0);
const is_socios_website = ref(false);

const login_words = computed(() => loading.value ? "Cargando..." : "Ingresar");

const user = computed(() => store.state.user);

const setUser = (user) => store.commit("setUser", user);
const setAuthenticatedTrue = () => store.commit("setAuthenticatedTrue");
const setBusinessIdentifier = (business) => store.commit("setBusinessIdentifier", business);
const setBusinessLocalId = (local) => store.commit("setBusinessLocalId", local);
const setConfigurationsAdminWeb = (config) => store.commit("setConfigurationsAdminWeb", config);
const setShowModalLoginRegister = (value) => store.commit("setShowModalLoginRegister", value);
const setIsNotManualNavigation = () => store.commit("setIsNotManualNavigation");

async function getBusinessList() {
  let response = await AdminServices.getBusinessList();
  if (response.data.success) {
    business_list.value = response.data.data;
    business_list.value = business_list.value.filter((item) => {
      if (item.id !== 45) {
        return item;
      }
    });
    business_list.value.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  } else {
    toast.error(response.data.message);
  }
}

function closeModalLogin() {
  setShowModalLoginRegister(false);
}

async function loginButton() {
  if (businessName.value === null) {
    toast.error("Debe seleccionar el nombre del negocio donde labora.");
    return;
  }
  setBusinessIdentifier(businessName.value.name_app)
  console.log("habia escogido", businessName.value)
  try {
    loading.value = true;
    await User.setBusinessHeader(businessName.value.name_app);
    let response = await User.login(login.value);

    if (response.data.success) {
      await User.setAuthHeaders(response.data.data.token);
      setBusinessLocalId(response.data.data.local);
      if (response.data.data.is_staff) {
        for (const key in notificationStatus.value) {
          if (Object.hasOwnProperty.call(notificationStatus.value, key)) {
            notificationStatus.value[key] = false;
          }
        }
      }
      if (businessName.value.business_permissions.includes('7') || response.data.data.is_staff) {
        notificationStatus.value.auto_print_software = true
      }
      setConfigurationsAdminWeb(notificationStatus.value);
      toast.success(`Bienvenido ${response.data.data.full_name}`);
      setUser(response.data.data);
      setAuthenticatedTrue();
      loading.value = true;
      emits("close");
      setIsNotManualNavigation()
      if (user.value.has_invoice_quickeat && user.value.admin) {
        await router.push({path: `/cajas`});
        return;
      }
      if (user.value.admin) {
        await router.push({path: `/orders`});
        return;
      }
      if (user.value.waiter) {
        await router.push("/inhouseOrders");
        return;
      }
      if (user.value.chef) {
        await router.push("/kitchenDisplaySystem");
        return;
      }
      return;
    }
    loading.value = false;
    toast.error(response.data.message);
  } catch (error) {
    toast.error(error);
    loading.value = false;
  }
}

watch(activeRef,() => {
  reset_key.value += 1;
  loading.value = false;
  login.value.email = "";
  login.value.password = "";
})

onMounted(async() => {
  let url = window.location.href;
  let urlObj = new URL(url);
  let subdominio = urlObj.hostname.split(".")[0].toLowerCase();

  let allowed_list = ["localhost", "socios", "respaldo", "qe", "test"];
  // is_socios_website.value = true;
  await getBusinessList();
  if (allowed_list.includes(subdominio)) {
    is_socios_website.value = true;
    return
  }
    const found = business_list.value.find(item => item.name_app === subdominio)
    if (found) {
      businessName.value = found
      console.log(businessName.value)
    }
})
</script>

<style>
h2 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: var(--semibold);
}

p {
  font-weight: var(--regular);
}

.component_login-information {
  text-align: center;
}
</style>
