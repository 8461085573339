import axios from "axios";

export default {
  async getLocalPassword(local_id) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/local/${local_id}/invoice/discount/`,
    });
  },

  async validateDiscountAdmin(local_id, data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/local/${local_id}/invoice/discount/`,
      data,
    });
  },

  async updateLocalPassword(local_id, data) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/local/${local_id}/invoice/discount/`,
      data,
    });
  },

  async get_simple_local_list() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/local/simple/list/`,
    });
  },

  async getBusinessPaymentCards() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/business/card/`,
    });
  },

  async get_local_info(local_id) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/local/info/${local_id}`,
    });
  },

  async update_local(
      data = {
        active_local: false,
        delivery_local: false,
        reservation_local: false,
        pickup_local: false,
        available_pay_user: false,
        available_pos: false,
        available_message: false,
        message: "",
      },
      local_id
  ) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/local/info/${local_id}`,
      data,
    });
  },

  async getLocalListStaffInfo() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/local/staff/0`
    });
  },

  async assignStaffToLocal(data, local_id) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/local/staff/${local_id}`,
      data,
    });
  },

  async updateLocalStaffRole(data = {
    user_id: 0,
    is_admin: false,
    is_chef: false,
    is_waiter: false,
    is_manager: false
  }, local_id) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/local/staff/${local_id}`,
      data,
    });
  },

  async removeStaffFromLocal(local_id, user_id) {
    return await axios({
      params: {
        user_id,
      },
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/local/staff/${local_id}`
    });
  },

  async duplicateLocal(local_id) {
    // duplicar local
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/local/${local_id}/duplicate/`,
    });
  },

  async deleteLocal(local_id) {
    // duplicar local
    return await axios({
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/local/${local_id}/duplicate/`,
    });
  },

  async get_schedules(local_id) {
    return await axios({
      params: {
        local_id,
      },
      method: "GET",
      url: `${process.env.VUE_APP_API}/local/schedules/${local_id}`,
    });
  },

  async set_schedules(local_id, data) {
    return await axios({
      params: {
        local_id,
      },
      method: "PUT",
      url: `${process.env.VUE_APP_API}/local/schedules/${local_id}`,
      data: data,
    });
  },

  async getPriceDelivery(id, lat, lon, type) {
    return await axios({
      params: {
        lat,
        lon,
        type,
      },
      method: "GET",
      url: `${process.env.VUE_APP_API}/local/${id}/check`,
    });
  },
  async listLocalesCercanos(lat, lon) {
    return await axios({
      params: {
        lat,
        lon,
      },
      method: "GET",
      url: `${process.env.VUE_APP_API}/local`,
    });
  },
  async listCategoriesOfLocal(idLocal) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/category/`,
      params: {
        local: idLocal,
      },
    });
  },
  async listAllProductsFromLocal(type_order, local_id) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/admin/local/${local_id}`,
      params: {
        type_order: type_order,
      },
    });
  },
  async singleProductFullInfo(type_order, product_id) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/user/info/${product_id}`,
      params: {
        type_order: type_order,
      },
    });
  },
  async listOfAditionalItemsForLocal(local_id) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/additional_items/${local_id}`,
    });
  },
  async listProductsOfCategory(idCategory) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/products/`,
      params: {
        category: idCategory,
      },
    });
  },

  async listOfAditionalItems() {
    // TODO: Fix zero value
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/additional_items/0`,
    });
  },
};
