import axios from "axios";

export default {

  async getLocalSingleInvoices() {
    return await axios({
      method: 'get',
      url: `${process.env.VUE_APP_API}/order/invoice/single/`,
    })
  },

  async registerSingleInvoice(data, local_id) {
    return await axios({
      params: {
        local_id: local_id
      },
      method: 'POST',
      url: `${process.env.VUE_APP_API}/order/invoice/single/`,
      data
    })
  },

  async sendSingleInvoiceByEmail(data = {
    id: 0,
    email: ''
  }) {
    return await axios({
      method: 'put',
      url: `${process.env.VUE_APP_API}/order/invoice/single/`,
      data
    })
  },

  async cancelSingleInvoice(invoice_id) {
    return await axios({
      method: 'DELETE',
      params: {
        invoice_id: invoice_id
      },
      url: `${process.env.VUE_APP_API}/order/invoice/single/`
    })
  },

  async retrySingleInvoiceGeneration(invoice_id) {
    return await axios({
      method: 'GET',
      params: {
        invoice_id: invoice_id
      },
      url: `${process.env.VUE_APP_API}/order/invoice/single/retry/`
    })
  },

  async getYangoCourierPrice(order_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/yango/courier/shipping/confirm/${order_id}/`,
    });
  },

  async confirmYangoCourier(order_id, data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/yango/courier/shipping/confirm/${order_id}/`,
      data
    });
  },

  async updateShippingOrderTrackingYangoCourier(order_id) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/yango/courier/tracking/${order_id}/`,
    });
  },

  async cancelYangoCourierRequest(order_id) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/yango/courier/cancel/${order_id}/`,
    });
  },

  async getCurrentRequestInfoYangoCourier(order_id) {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/yango/courier/info/${order_id}/`,
    });
  },

  async getPedidosYaCourierPrice(order_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/pedidosya/shipping/confirm/${order_id}/`,
    });
  },

  async confirmCourierPedidosYa(order_id) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/pedidosya/shipping/confirm/${order_id}/`,
    });
  },

  async updateShippingOrderTrackingPedidosYa(order_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/pedidosya/shipping/tracking/${order_id}/`,
    });
  },

  async cancelPedidosYaShippingRequest(order_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/pedidosya/shipping/cancel/${order_id}/`,
    });
  },

  async getPedidosYaRequestData(order_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/pedidosya/shipping/info/${order_id}/`,
    });
  },

  async sendWaiterNotification(id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/order/kitchen/notify/waiter/${id}/`,
    });
  },

  async printInvoiceBySoftware(order_id, data = {
    print_invoice: false,
    print_details: false
  }) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/print/repeat/${order_id}/`,
      data
    });
  },

  async printComandaBySoftware(id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/order/print/repeat/details/${id}/`,
    });
  },

  async printPrecheckBySoftware(discount, table_turn_id) {
    return await axios({
      method: "get",
      params: {
        'precheck_discount': discount
      },
      url: `${process.env.VUE_APP_API}/order/print/repeat/precheck/${table_turn_id}/`,
    });
  },

  async printPrecheckMultipleBySoftware(table_turn_id, data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/print/repeat/precheck/${table_turn_id}/`,
      data
    });
  },

  async getOrderDetailsUpdateKitchen(detail_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/order/admin/single/details/${detail_id}/`,
    });
  },

  async getKitchenAreaPlates(local_id, kitchen_area_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/order/kitchen/${local_id}/${kitchen_area_id}/`,
    });
  },

  async getHistoricKitchenAreaPlates(
    local_id,
    kitchen_area_id,
    data = {
      date_history: "",
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/kitchen/${local_id}/${kitchen_area_id}/`,
      data,
    });
  },

  async updateKitchenAreaPlateStatus(
    local_id,
    kitchen_area_id,
    data = {
      has_order: false,
      id: 0,
    }
  ) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/order/kitchen/${local_id}/${kitchen_area_id}/`,
      data,
    });
  },

  async getTableTurnOrders(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/table/order_list/`,
      data,
    });
  },

  async getLocalTablePlates(table_turn_id) {
    return await axios({
      params: {
        table_turn_id: table_turn_id,
      },
      method: "get",
      url: `${process.env.VUE_APP_API}/order/table/plate_list/`,
    });
  },

  async createLocalTablePlates(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/table/plate_list/`,
      data,
    });
  },

  async deleteLocalTablePlate(data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/order/table/plate_list/`,
      data,
    });
  },

  async getAllLocalTableAreas(local_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/order/table/area/${local_id}/`,
    });
  },

  async createAreaTable(local_id, data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/order/table/area/${local_id}/`,
      data,
    });
  },

  async updateAreaTable(local_id, data) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/order/table/area/${local_id}/`,
      data,
    });
  },

  async deleteAreaTable(
    local_id,
    data = {
      local_table_area_id: 0,
    }
  ) {
    return await axios({
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/order/table/area/${local_id}/`,
      data,
    });
  },

  async transferTableTurn(
      data = {
        origin_table_id: 0,
        destination_table_id: 0,
      }
  ) {
    return await axios({
      method: "POST",
        url: `${process.env.VUE_APP_API}/order/table/local/change/`,
      data,
    });
  },

  async updateTableInfo(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/order/table/local/change/`,
      data,
    });
  },

  async getAllLocalTables(local_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/order/table/local/${local_id}/`,
    });
  },

  async createLocalTable(
    local_id,
    data = {
      local_table_area_id: 0,
    }
  ) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/order/table/local/${local_id}/`,
      data,
    });
  },

  async updateAllTablesMap(
    data = {
      id: 0,
      table_shape: 0,
      chair_quantity: 0,
      rotation_angle: 0,
      width: 0,
      height: 0,
      x_coordinate: 0,
      y_coordinate: 0,
    }
  ) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/order/table/map/massive/`,
      data,
    });
  },

  async createTableMap(
    data = {
      local_table_area_id: 0,
      table_shape: 0,
      x_coordinate: 0,
      y_coordinate: 0,
    }
  ) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/order/table/map/single/`,
      data,
    });
  },

  async updateTableMap(data) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/order/table/map/single`,
      data,
    });
  },

  async deleteTableMap(table_id) {
    return await axios({
      params: {
        id: table_id,
      },
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/order/table/map/single`,
    });
  },

  async deleteDecorativeFromMap(local_id){
    return await axios({
      params: {
        id: local_id,
      },
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/order/table/floor/${local_id}/decoration/`,
    })
  },

  async createDecorativeFromMap(
    local_id,
    data = {
      floor: 0,
      decoration_shape: 0,
      x_coordinate: 0,
      y_coordinate: 0,
    }
  ) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/order/table/floor/${local_id}/decoration/`,
      data,
    });
  },

  async updateDecorativeFromMap(
      local_id,
      data = {
        id: 0,
        decoration_shape: 0,
        description: "",
        rotation_angle: 0,
        width: 0,
        height: 0,
        x_coordinate: 0,
        y_coordinate: 0,
      }
  ){
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/order/table/floor/${local_id}/decoration/`,
      data,
    })
  },

  async updateLocalTable(
    local_id,
    data = {
      local_table_id: 0,
      waiter_id: 0,
      end_turn: false,
    }
  ) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/order/table/local/${local_id}/`,
      data,
    });
  },

  async deleteLocalTable(
    local_id,
    data = {
      local_table_area_id: 0,
    }
  ) {
    return await axios({
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/order/table/local/${local_id}/`,
      data,
    });
  },

  async testBackend() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/order/rating`,
    });
  },

  async change_order_status(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/order/admin`,
      data,
    });
  },

  async changeOrderPaymentType(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/admin`,
      data,
    });
  },

  async generateInvoice(localId, orderId) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/admin/${orderId}/`,
    });
  },

  async change_order_local(localId, orderId) {
    return await axios({
      params: {
        local: localId,
      },
      method: "put",
      url: `${process.env.VUE_APP_API}/order/admin/${orderId}/`,
    });
  },

  async cancel_order(
      data = {
        reason: "",
        discard_ingredients: true,
      },
      orderId
  ) {
    return await axios({
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/order/admin/${orderId}/`,
      data,
    });
  },
  async getOrdersQueueScreen(id_local, status) {
    return await axios({
      params: {
        status: status,
      },
      method: "get",
      url: `${process.env.VUE_APP_API}/order/admin/${id_local}/local_screen`,
    });
  },
  async get_orders_local_new(id_local, status) {
    return await axios({
      params: {
        status: status,
      },
      method: "get",
      url: `${process.env.VUE_APP_API}/order/admin/${id_local}/new/`,
    });
  },
  async getListOrdersHistory(
    data = {
      date_history: "",
      local: 1,
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/admin/history/new/`,
      data,
    });
  },
  async getListOrdersHistoryByDatetime(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/order/admin/history/new/`,
      data,
    });
  },
  async detailOrder(data = { order: "" }) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/detail`,
      data,
    });
  },
  async removeMotorizedFromOrder(data = { order: 0 }) {
    //orderId
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/order/motorized/notify/`,
      data,
    });
  },
  async notify_all_motorized_in_business(data = { order: 0 }) {
    //orderId
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/motorized/notify/`,
      data,
    });
  },
  async get_locales() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/order/admin/locals/`,
    });
  },

  async getSingleOrderUpdate(order_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/order/admin/single/${order_id}`,
    });
  },

  async sendOrderInvoice(order_id, data = {
    email: ''
  }) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/admin/single/${order_id}`,
      data
    });
  },
  async assign_order(data = { order: "" }) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/new/`,
      data,
    });
  },
  async createSingleOrderInhouse(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/new/inhouse/single/`,
      data,
    });
  },
  async createMultipleOrdersInhouse(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/new/inhouse/multiple/`,
      data,
    });
  },
  async searchRucDni(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/admin/ruc/`,
      data,
    });
  },
  async changeInvoiceInfo(data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/order/admin/ruc/`,
      data,
    });
  },
  async calculate_delivery(data = { order: "" }) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/price/delivery/`,
      data,
    });
  },

  async getCashRegisters(local_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/order/cash_register/local/${local_id}`,
    });
  },

  async createCashRegisterObj(local_id, data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/cash_register/local/${local_id}`,
      data,
    });
  },

  async closeCashRegister(local_id, data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/order/cash_register/local/${local_id}`,
      data,
    });
  },

  async selfAssignCashRegister(local_id, data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/order/cash_register/local/${local_id}`,
      data,
    });
  },

  async updateCashRegisterData(local_id, data) {
    return await axios({
      method: "put",
      url: `${process.env.VUE_APP_API}/order/cash_register/local/${local_id}`,
      data,
    });
  },

  async suspendCashRegister(local_id, data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/order/cash_register/local/${local_id}`,
      data,
    });
  },

  async deleteCashRegister(local_id, data) {
    return await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API}/order/cash_register/local/${local_id}`,
      data,
    });
  },

  async getCashRegisterOrders(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/cash_register/order_list/`,
      data,
    });
  },

  async getCashRegisterTurnsHistoricData(
    data = {
      cash_register_id: 0,
      date_start: "",
      date_end: "",
    }
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/cash_register/history/`,
      data,
    });
  },

  async getCashRegisterCashflowData(cash_turn_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/order/cash_register/cashflow/${cash_turn_id}`,
    });
  },

  async postNewCashflowMovement(
    data = {
      amount_cash: 0,
      description: "",
    },
    cash_turn_id
  ) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/order/cash_register/cashflow/${cash_turn_id}`,
      data,
    });
  },
};
