<template>
  <div class="wrapper__header">
    <header class="header">
      <div class="header__container">
        <a data-v-41d6f4fc="" class="navbar-brand">
          <svg
            @click="isActiveBarraLateralAdmin = !isActiveBarraLateralAdmin"
            data-v-41d6f4fc=""
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 100 100"
          >
            <defs data-v-41d6f4fc="">
              <clipPath data-v-41d6f4fc="" id="clip-side_bar_icon">
                <rect data-v-41d6f4fc="" width="100" height="100"></rect>
              </clipPath>
            </defs>
            <g
              data-v-41d6f4fc=""
              id="side_bar_icon"
              clip-path="url(#clip-side_bar_icon)"
            >
              <g
                data-v-41d6f4fc=""
                id="Group_2"
                data-name="Group 2"
                transform="translate(-2803 -84)"
              >
                <rect
                  data-v-41d6f4fc=""
                  id="Rectangle_2"
                  data-name="Rectangle 2"
                  width="98"
                  height="17.043"
                  rx="4"
                  transform="translate(2804 96)"
                ></rect>
                <rect
                  data-v-41d6f4fc=""
                  id="Rectangle_3"
                  data-name="Rectangle 3"
                  width="98"
                  height="17.043"
                  rx="4"
                  transform="translate(2804 125.826)"
                ></rect>
                <rect
                  data-v-41d6f4fc=""
                  id="Rectangle_4"
                  data-name="Rectangle 4"
                  width="98"
                  height="17.043"
                  rx="4"
                  transform="translate(2804 155.652)"
                ></rect>
              </g>
            </g>
          </svg>
        </a>
        <div data-v-41d6f4fc="" class="header__title" v-show="!isAuthenticated">
          <a
            data-v-41d6f4fc=""
            class="nav-link header_icon_admin"
            style="
              margin-left: 20px;
              margin-right: 20px;
              z-index: 2;
              cursor: pointer;
            "
          >
            <strong data-v-41d6f4fc="" @click="showModalLogin">
              Ingresar
            </strong>
          </a>
        </div>
        <div data-v-41d6f4fc="" class="header__title" v-show="isAuthenticated">
          <a
            data-v-41d6f4fc=""
            class="nav-link header_icon_admin"
            style="
              margin-left: 20px;
              margin-right: 20px;
              z-index: 2;
              cursor: pointer;
            "
          >
            <strong data-v-41d6f4fc="" @click="refresh">
              {{$route.query.headerTitle}}
            </strong>
          </a>
        </div>
        <div class="badge-component">
          <StatusBadge
              v-show="isAuthenticated && (user.admin || user.manager)"
              :status="colorLocalStatus">
            {{ storeStatus }}
          </StatusBadge>
          <div class="support" v-if="hasTutorial" >
               <div class="support__tutorial" @click="openTutorialLink">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.3333 21H5.33325C4.27273 20.9989 3.25597 20.5771 2.50608 19.8272C1.75617 19.0773 1.33439 18.0605 1.33325 17V8C1.33439 6.93948 1.75617 5.92272 2.50608 5.17283C3.25597 4.42292 4.27273 4.00113 5.33325 4H19.3333C20.3938 4.00113 21.4106 4.42292 22.1605 5.17283C22.9103 5.92272 23.3322 6.93948 23.3333 8V17C23.3322 18.0605 22.9103 19.0773 22.1605 19.8272C21.4106 20.5771 20.3938 20.9989 19.3333 21ZM5.33325 6C4.803 6.0006 4.29465 6.21152 3.91971 6.58645C3.54477 6.9614 3.33385 7.46975 3.33325 8V17C3.33385 17.5303 3.54477 18.0387 3.91971 18.4136C4.29465 18.7885 4.803 18.9993 5.33325 19H19.3333C19.8635 18.9993 20.3719 18.7885 20.7469 18.4136C21.1218 18.0387 21.3326 17.5303 21.3333 17V8C21.3326 7.46975 21.1218 6.9614 20.7469 6.58645C20.3719 6.21152 19.8635 6.0006 19.3333 6H5.33325Z" fill="#F15A24"/>
                    <path d="M10.5642 16.5017C10.0639 16.5008 9.58437 16.3013 9.23087 15.9473C8.87735 15.5932 8.67869 15.1135 8.67847 14.6131V10.3875C8.67836 10.0658 8.76055 9.74934 8.91719 9.46829C9.07383 9.18725 9.29973 8.95093 9.57344 8.78178C9.84713 8.61264 10.1595 8.51628 10.481 8.50189C10.8024 8.48749 11.1222 8.55553 11.4099 8.69953L15.6355 10.8123C15.9491 10.9689 16.213 11.2098 16.3974 11.5079C16.5818 11.8061 16.6794 12.1497 16.6794 12.5003C16.6794 12.8509 16.5818 13.1945 16.3974 13.4927C16.213 13.7908 15.9491 14.0317 15.6355 14.1883L11.4099 16.3011C11.1474 16.4328 10.8579 16.5016 10.5642 16.5017ZM10.6785 10.5701V14.4305L14.5389 12.5003L10.6785 10.5701Z" fill="#F15A24"/>
                  </svg>
                  <p>Ver tutorial</p>
                </div>
                <div class="support__separator"
                     v-if="false"
                ></div>
            <div
                v-if="false"
                @click="goToDynamicPath('blogSupportTools', `Ayuda`)"
                class="support__tutorial"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.95 18C12.3 18 12.5958 17.8792 12.8375 17.6375C13.0792 17.3958 13.2 17.1 13.2 16.75C13.2 16.4 13.0792 16.1042 12.8375 15.8625C12.5958 15.6208 12.3 15.5 11.95 15.5C11.6 15.5 11.3042 15.6208 11.0625 15.8625C10.8208 16.1042 10.7 16.4 10.7 16.75C10.7 17.1 10.8208 17.3958 11.0625 17.6375C11.3042 17.8792 11.6 18 11.95 18ZM11.05 14.15H12.9C12.9 13.6 12.9625 13.1667 13.0875 12.85C13.2125 12.5333 13.5667 12.1 14.15 11.55C14.5833 11.1167 14.925 10.7042 15.175 10.3125C15.425 9.92083 15.55 9.45 15.55 8.9C15.55 7.96667 15.2083 7.25 14.525 6.75C13.8417 6.25 13.0333 6 12.1 6C11.15 6 10.3792 6.25 9.7875 6.75C9.19583 7.25 8.78333 7.85 8.55 8.55L10.2 9.2C10.2833 8.9 10.4708 8.575 10.7625 8.225C11.0542 7.875 11.5 7.7 12.1 7.7C12.6333 7.7 13.0333 7.84583 13.3 8.1375C13.5667 8.42917 13.7 8.75 13.7 9.1C13.7 9.43333 13.6 9.74583 13.4 10.0375C13.2 10.3292 12.95 10.6 12.65 10.85C11.9167 11.5 11.4667 11.9917 11.3 12.325C11.1333 12.6583 11.05 13.2667 11.05 14.15ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="#F15A24"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="pending_payment_notification" v-if="pendingPayment">
      <strong style="margin-right: 10px">Pago pendiente:</strong>Tiene un recibo
      pendiente. {{ mensajeTolerancia }}
      <div style="margin-left: 10px">
        <!--    <strong>Ir a Pagos del Sistema.</strong>-->
      </div>
    </div>
    <transition name="slide-fade">
      <div
        v-show="isActiveBarraLateralAdmin"
        class="fondo__menu__lateral__modal"
        @click="isActiveBarraLateralAdmin = !isActiveBarraLateralAdmin"
      ></div>
    </transition>
    <transition name="slide-fade">
      <RebrandBarraLateral
        v-show="currentRouteName() !== undefined"
        :isActive="isActiveBarraLateralAdmin"
        :routeName="currentRouteName()"
        @close="isActiveBarraLateralAdmin = false"
      ></RebrandBarraLateral>
    </transition>
  </div>
</template>

<script setup>
import RebrandBarraLateral from "@/components/MainBarraLateral";
import moment from "moment";
import LocalServices from "@/services/local-services";
import StatusBadge from "@/components/new/buttons/StatusBadge";
import {ref, watch, computed, onMounted, getCurrentInstance, onBeforeUnmount} from "vue";
import { useStore } from "vuex";
import {useRoute, useRouter} from "vue-router";

const { appContext } = getCurrentInstance();
const toast = appContext.config.globalProperties.$toast;

const store = useStore();
const router = useRouter();
const user = computed(() => store.state.user);
const route = useRoute();
const hasTutorial = ref(false);
const showScreenSupportTools = ref(false);
const isMobile = ref(window.innerWidth <= 768);
// const listSupportTools = ref([
//   { route: 'cajaSuperAdmin'},
//   { route: 'cajaAdmin'},
//   { route: 'menuList'},
//   { route: 'menuListSuperAdmin'},
//   { route: 'menuRecipe'},
//   { route: 'assignOrderDelivery'},
// ]);
// const titulo = ref("INICIO");
const isActiveBarraLateralAdmin = ref(false);
const fechaLimite = ref("");
const fechaActual = ref(moment());

const setAdminLocalInfo = (payload) => {
  store.commit('setAdminLocalInfo', payload);
};

const setShowModalLoginRegister = (payload) => {
  store.commit('setShowModalLoginRegister', payload);
};

const isAuthenticated = computed(() => store.state.isAuthenticated);
const adminLocalInfo = computed(() => store.state.adminLocalInfo);

// const currentElement = computed(()=> {
//   return listSupportTools.value.find((element) => element.route === route.name)
// })

const diasRestantes = computed(()=> {
  return fechaLimite.value.diff(fechaActual.value, "days");
})

const mensajeTolerancia = computed(()=> {
  moment.locale("es");
  const diaSemana = fechaLimite.value.format("dddd");
  const fechaFormateada = fechaLimite.value.format(
      "D [de] MMMM [de] YYYY HH:mm"
  );
  if (fechaActual.value.isAfter(fechaLimite.value)) {
    return `Servicio suspendido hasta que regularicen su pago`;
  }
  return `Si no cancela hasta el ${diaSemana}, ${fechaFormateada} (en ${diasRestantes.value} días) se suspenderá su servicio.`;
})

const storeStatus = computed(()=> {
  if (!adminLocalInfo.value.is_available_local) {
    return isMobile.value ? "No disponible" : "Tienda no disponible";
  }
  if (!adminLocalInfo.value.is_in_area) {
    return isMobile.value ? "Fuera de horario" : "Fuera de horario de atención";
  }
  return isMobile.value ? "En línea" : "Tienda en línea";
})

const colorLocalStatus = computed(()=>{
  if (adminLocalInfo.value.is_available_local && adminLocalInfo.value.is_in_area) {
    return null;
  } else {
    return "danger";
  }
})

const pendingPayment = computed(() => {
  return (isAuthenticated.value && 10 < new Date().getDate() && user.value.is_pending_payment);
})

function goToDynamicPath(link, headerTitle) {
  showScreenSupportTools.value = true
  router.push({ path: `/${link}`, query: { headerTitle: headerTitle } });
}

function openTutorialLink(){
  window.open(route.meta.tutorialUrl, '_blank')
}

function checkIfMobile() {
  isMobile.value = window.innerWidth <= 768;
}

function showModalLogin() {
  setShowModalLoginRegister(true);
}

function currentRouteName() {
  return route.name;
}

async function loadLocalInfo() {
  console.log("obtiene info local")
  try {
    let response = await LocalServices.get_local_info(user.value.local);
    if (response.data.success) {
      setAdminLocalInfo(response.data.data);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
}

function refresh() {
  location.reload();
}

onMounted(async () => {
  if (route.meta.tutorialUrl !== "" ) {
    hasTutorial.value = true
  }
  fechaLimite.value = moment({
    year: fechaActual.value.year(),
    month: fechaActual.value.month(),
    date: 16,
    hour: 23,
    minute: 59,
    second: 0,
  });

  window.addEventListener('resize', checkIfMobile);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkIfMobile);
});

watch(isAuthenticated, async () => {
  console.log("watch isAuth", isAuthenticated.value)
  if (isAuthenticated.value && user.value.admin) {
    await loadLocalInfo();
  }
});

watch(route, (newValue) => {
  hasTutorial.value = newValue.meta.tutorialUrl !== "";
})
</script>

<style scoped>
.wrapper__header {
  /*    position: sticky;
    top: 0;*/
  z-index: 10;
}

.fondo__menu__lateral__modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 3;
  background: #00000060;
  left: 0;
  top: 0;
  transition: 0.3s ease all;
  cursor: pointer;
}

.header {
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 1px 3px var(--plomito);
  z-index: 10;
}

.header__container {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 56px;
  padding: 0 16px;
  box-shadow: 0 1px 3px var(--plomito);
}

.navbar-brand {
  height: 25px;
  cursor: pointer;
}

.badge-component{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.header__title a {
  transition: color 0.3s linear;
  color: var(--text-black);
}

.header__title a:hover {
  color: #fca600;
}

.pending_payment_notification {
  display: flex;
  justify-content: center;
  color: white;
  background: #c22f29;
  width: 100%;
  padding: 10px 16px;
  font-size: 14px;
  z-index: 3;
}

.header_icon_admin {
  display: inline;
  transform: scale(1.1);
}

.support{
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  height: 56px;
  min-height: 56px;
}

.support__tutorial{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.support p{
  display: none;
  color: var(--primary);
}

.support__separator{
  width: 2px;
  height: 15px;
  background-color: var(--outline-first);
}

.support > svg{
  cursor: pointer;
}

@media screen and (min-width: 768px){
  .badge-component{
    gap: 30px;
  }

  .support{
    gap: 30px;
  }

  .support__tutorial{
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .support p{
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .header__container {
    padding: 0 40px;
    gap: 20px;
  }
  .header__title {
    font-size: 24px;
  }
}
</style>
